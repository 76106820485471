@import "theme/variables.scss";

.alert {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // width: 300px;
  margin: 16px !important;
  padding: 16px;
  font-family: $font;
  // text-transform: uppercase;
  color: $dark;
  border-radius: 4px;
  background-color: $white;
  box-shadow: 0px 2px 2px 2px $shadow;
  box-sizing: border-box;

  .alert-button {
    margin-left: 20px;
    color: $dark;
    border: none;
    background-color: transparent;
    cursor: pointer;

    svg { stroke: $dark; }
  }
}