@use './variables.scss' as *;

/*****+/ FONTS /+*****/
@use './fonts.scss';

/*****+/ BASE /+*****/
html {
  height: 100%;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  position: relative;
  font-family: $font;
  font-weight: $fontWeight;
  font-size: 16px;
  line-height: 1.5;
  color: $dark;
  -webkit-font-smoothing: antialiased;
  background: $light;
}
/* fixes mobile menu overflow bug */
html, body {
  overflow-x: hidden;
}

main {
  position: relative;
  // padding-top: 64px;

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: $fontHeadline;
    font-weight: $fontWeightHeadline;
  }
  h2 {
    font-size: 2.25rem;
  }

  h1.intro-heading {
    font-size: 3.5em;

    .text-wrapper {
      position: relative;
      display: inline-block;
      padding-top: 0.1em;
      padding-right: 0.05em;
      padding-bottom: 0.15em;
      line-height: 1em;
      text-align: center;
    }

    .line {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 3px;
      width: 100%;
      background-color: #402d2d;
      transform-origin: 0.5 0;
    }
    .line.line1 {
      margin-top: 0;
      top: calc(0.3125em + 0.15625em);
    }
    .line.line2 {
      margin-bottom: 0;
      bottom: 0.3125em;
    }

    .ampersand {
      width: calc(1em + 16px);
      padding: 0 8px;
      font-family: Baskerville, 'EB Garamond', serif;
      font-style: italic;
      font-weight: 400;
      font-size: 80%;
      text-align: center;
    }

    .letters {
      display: inline-block;
      opacity: 0;
    }

    .letters-left,
    .letters-right {
      font-size: 70%;
    }
  }
}
@media only screen and (max-width: 900px) {
  main {
    h1.intro-heading {
      text-align: center;
    }
  }
}

/*****+/ UTILITY CLASSES /+*****/
.clearfix {
  clear: both;
}
.clearfix::after,
.clearfix::before {
  content: ' ';
  display: table;
}

.display-block {
  display: block !important;
}
.display-inline-block {
  display: inline-block !important;
}
.display-inline {
  display: inline !important;
}
.display-none {
  display: none !important;
}

.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-justify {
  text-align: justify !important;
}

.text-primary {
  color: $primary !important;
}
// .text-secondary { color: $secondary !important; }
.text-dark {
  color: $dark !important;
}
.text-light {
  color: $light !important;
}
.text-gray {
  color: $gray !important;
}

.bg-primary {
  background-color: $primary !important;
}
// .bg-secondary { background-color: $secondary !important; }
.bg-dark {
  background-color: $dark !important;
}
.bg-light {
  background-color: $light !important;
}
.bg-gray {
  background-color: $gray !important;
}

.font-normal {
  font-family: $font;
}
.font-headline {
  font-family: $fontHeadline;
  font-weight: $fontWeightHeadline;
}
.font-bold {
  font-weight: $fontWeightBold;
}

.noborder {
  border: none !important;
}

.mr-auto {
  margin-right: auto !important;
}
.ml-auto {
  margin-left: auto !important;
}
.mlr-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.mrl-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 8px !important;
}
.mt-2 {
  margin-top: 16px !important;
}
.mt-3 {
  margin-top: 24px !important;
}
.mt-4 {
  margin-top: 32px !important;
}
.mt-5 {
  margin-top: 40px !important;
}
.mt-6 {
  margin-top: 48px !important;
}
.mt-7 {
  margin-top: 56px !important;
}
.mt-8 {
  margin-top: 64px !important;
}

.mr-0 {
  margin-right: 0 !important;
}
.mr-1 {
  margin-right: 8px !important;
}
.mr-2 {
  margin-right: 16px !important;
}
.mr-3 {
  margin-right: 24px !important;
}
.mr-4 {
  margin-right: 32px !important;
}
.mr-5 {
  margin-right: 40px !important;
}
.mr-6 {
  margin-right: 48px !important;
}
.mr-7 {
  margin-right: 56px !important;
}
.mr-8 {
  margin-right: 64px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 8px !important;
}
.mb-2 {
  margin-bottom: 16px !important;
}
.mb-3 {
  margin-bottom: 24px !important;
}
.mb-4 {
  margin-bottom: 32px !important;
}
.mb-5 {
  margin-bottom: 40px !important;
}
.mb-6 {
  margin-bottom: 48px !important;
}
.mb-7 {
  margin-bottom: 56px !important;
}
.mb-8 {
  margin-bottom: 64px !important;
}

.ml-0 {
  margin-left: 0 !important;
}
.ml-1 {
  margin-left: 8px !important;
}
.ml-2 {
  margin-left: 16px !important;
}
.ml-3 {
  margin-left: 24px !important;
}
.ml-4 {
  margin-left: 32px !important;
}
.ml-5 {
  margin-left: 40px !important;
}
.ml-6 {
  margin-left: 48px !important;
}
.ml-7 {
  margin-left: 56px !important;
}
.ml-8 {
  margin-left: 64px !important;
}

.pt-0 {
  padding-top: 0 !important;
}
.pt-1 {
  padding-top: 8px !important;
}
.pt-2 {
  padding-top: 16px !important;
}
.pt-3 {
  padding-top: 24px !important;
}
.pt-4 {
  padding-top: 32px !important;
}
.pt-5 {
  padding-top: 40px !important;
}
.pt-6 {
  padding-top: 48px !important;
}
.pt-7 {
  padding-top: 56px !important;
}
.pt-8 {
  padding-top: 64px !important;
}

.pr-0 {
  padding-right: 0 !important;
}
.pr-1 {
  padding-right: 8px !important;
}
.pr-2 {
  padding-right: 16px !important;
}
.pr-3 {
  padding-right: 24px !important;
}
.pr-4 {
  padding-right: 32px !important;
}
.pr-5 {
  padding-right: 40px !important;
}
.pr-6 {
  padding-right: 48px !important;
}
.pr-7 {
  padding-right: 56px !important;
}
.pr-8 {
  padding-right: 64px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}
.pb-1 {
  padding-bottom: 8px !important;
}
.pb-2 {
  padding-bottom: 16px !important;
}
.pb-3 {
  padding-bottom: 24px !important;
}
.pb-4 {
  padding-bottom: 32px !important;
}
.pb-5 {
  padding-bottom: 40px !important;
}
.pb-6 {
  padding-bottom: 48px !important;
}
.pb-7 {
  padding-bottom: 56px !important;
}
.pb-8 {
  padding-bottom: 64px !important;
}

.pl-0 {
  padding-left: 0 !important;
}
.pl-1 {
  padding-left: 8px !important;
}
.pl-2 {
  padding-left: 16px !important;
}
.pl-3 {
  padding-left: 24px !important;
}
.pl-4 {
  padding-left: 32px !important;
}
.pl-5 {
  padding-left: 40px !important;
}
.pl-6 {
  padding-left: 48px !important;
}
.pl-7 {
  padding-left: 56px !important;
}
.pl-8 {
  padding-left: 64px !important;
}

@media only screen and (max-width: 960px) {
  .tablet-show {
    display: initial !important;
  }
  .tablet-hide {
    display: none !important;
  }

  .tablet-text-left {
    text-align: left !important;
  }
  .tablet-text-right {
    text-align: right !important;
  }
  .tablet-text-center {
    text-align: center !important;
  }
  .tablet-text-justify {
    text-align: justify !important;
  }

  .tablet-h1-small {
    font-size: 1em;
  }
}
@media only screen and (max-width: 700px) {
  .mobile-show {
    display: initial !important;
  }
  .mobile-hide {
    display: none !important;
  }

  .mobile-text-left {
    text-align: left !important;
  }
  .mobile-text-right {
    text-align: right !important;
  }
  .mobile-text-center {
    text-align: center !important;
  }
  .mobile-text-justify {
    text-align: justify !important;
  }

  .mobile-h1-small {
    font-size: 1em;
  }
}
