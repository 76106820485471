@use 'theme/variables.scss' as *;

$content: rgba(255, 255, 255, 0.42);
$contentHover: rgba(255, 255, 255, 0.64);
$divider: rgba(255, 255, 255, 0.12);
$background: #111;

#partner {
  background-color: $white;

  a {
    display: inline-block;
    margin: 0 16px 16px 16px;
    opacity: 0.42;
    transition: opacity $transitionTime $transitionType;
    cursor: pointer;

    img {
      width: 100%;
    }
  }
  a:hover {
    opacity: 1;
  }
}

#footer {
  color: $content;
  background-color: $background;

  .footer-row {
    padding: 64px 0;

    .footer-cell {
      text-align: center;

      ul {
        display: inline-block;
        margin: 0 auto;
        text-align: left;
        list-style: none inside;

        li::before {
          content: '-';
          padding-right: 5px;
        }
      }

      p {
        display: inline-block;
        margin: 0 auto;
        text-align: left;
      }
    }
  }

  .footer-content {
    padding: 48px 16px 0 16px;
    font-size: 0.8rem;
    text-align: center;

    .footer-logo {
      display: inline-block;
      font-size: 0;
      text-decoration: none;
      cursor: pointer;

      img {
        width: auto;
        height: 50px;
      }
    }

    a {
      font-weight: $fontWeightBold;
      text-decoration: none;
      vertical-align: middle;
      color: $content;
      transition: color $transitionTime $transitionType;
      cursor: pointer;
    }
    a:hover {
      color: $contentHover;
    }

    .footer-divider {
      position: relative;
      display: block;
      width: 90%;
      max-width: 500px;
      height: 2px;
      margin: 32px auto;
      border-radius: 2px;
      background-color: $divider;
    }
    .footer-divider.footer-divider-bottom:after {
      content: '';
      position: absolute;
      z-index: 1;
      top: -7px;
      left: calc(50% - 7px);
      width: 14px;
      height: 14px;
      transform: rotate(45deg);
      background-color: $background;
      border-bottom: 1px solid $divider;
      border-right: 1px solid $divider;
    }
    .footer-divider.footer-divider-top:after {
      content: '';
      position: absolute;
      z-index: 1;
      top: -7px;
      left: calc(50% - 7px);
      width: 14px;
      height: 14px;
      transform: rotate(45deg);
      background-color: $background;
      border-top: 1px solid $divider;
      border-left: 1px solid $divider;
    }

    .footer-social-icon {
      width: 24px;
      margin: 0 12px;
      opacity: 0.42;
      transition: opacity $transitionTime $transitionType;

      img {
        width: 24px;
      }
    }
    .footer-social-icon:hover {
      opacity: 0.64;
    }
  }

  .footer-copyright {
    padding: 8px 16px 48px 16px;
    font-size: 0.64rem;
    text-align: center;
  }
}
