/* Open Sans */
@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/open-sans/open-sans-v40-latin_latin-ext-300.woff2') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url('../assets/fonts/open-sans/open-sans-v40-latin_latin-ext-300italic.woff2') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/open-sans/open-sans-v40-latin_latin-ext-regular.woff2') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/open-sans/open-sans-v40-latin_latin-ext-700.woff2') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../assets/fonts/open-sans/open-sans-v40-latin_latin-ext-700italic.woff2') format('woff2');
}

/* Lobster */
@font-face {
  font-display: swap;
  font-family: 'Lobster';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/lobster/lobster-v30-latin_latin-ext-regular.woff2') format('woff2');
}
