$light: #f7f8fa;
$lightHover: rgb(227, 228, 230);
$lightTransparent: rgba(247, 248, 250, 0.8);
$dark: #000000;
$darkHover: rgba(0, 0, 0, 0.8);
$gray: #cccccc;
$grayHover: #e0e0e0;
$lightGray: rgba(204, 204, 204, 0.2);
$lightGrayWhiteBase: #f4f4f4;
$lighterGray: rgba(204, 204, 204, 0.5);

$primary: #ab2983;
$primaryHover: #c469a8;
$primaryTransparent: rgba(171, 41, 131, 0.64);
$primaryTransparent2: rgba(171, 41, 131, 0.42);

$white: #ffffff;

$shadow: rgba(0, 0, 0, 0.23);
$shadowSize: 0 0.5rem 1.875rem;
$darkShadow: rgba(0, 0, 0, 0.42);

$darkness: rgba(0, 0, 0, 0.5);

$font: 'Open Sans', sans-serif;
$fontWeight: 400;
$fontWeightBold: 600;

$fontHeadline: 'Lobster', sans-serif;
$fontWeightHeadline: 400;

$opacityHover: 0.8;

$transitionTime: 300ms;
$transitionTimeHalf: 150ms;
$transitionType: ease-in-out;

$default: #bac3d0;
$warning: #f1c40f;
$info: #659be0;
$success: #36c6d3;
$danger: #ed6b75;
