@use 'theme/variables.scss' as *;

$TOPBAR_HEIGHT: 64px;
$MOBILE_MAX_WIDTH: 1100px;

#topbar {
  position: fixed;
  display: block;
  width: 100%;
  height: $TOPBAR_HEIGHT;
  top: 0;
  right: 0;
  left: 0;
  text-align: right;
  border-right: 16px solid $primary;
  background-color: $light;
  background-image: url('/assets/images/topbar-divider.png');
  background-size: 128px 64px;
  background-position: 196px center;
  background-repeat: no-repeat;
  box-shadow: 0 1px 10px 0 $darkShadow;
  transition: border-color $transitionTime $transitionType, background-color $transitionTime $transitionType,
    box-shadow $transitionTime $transitionType;
  z-index: 1001;

  .topbar-logo {
    position: absolute;
    display: block;
    top: 7px;
    left: 7px;
    font-size: 0;
    text-decoration: none;
    cursor: pointer;

    img {
      width: auto;
      height: 50px;
    }
  }
}

#topbar-nav {
  display: inline-block;
  width: calc(100% - 324px);
  text-align: right;
  background-color: $primary;
  // background-image: url('/images/topbar-divider.png');
  // background-size: auto 64px;
  // background-position: left center;
  // background-repeat: no-repeat;
  transition: bbackground-color $transitionTime $transitionType;

  a {
    position: relative;
    display: inline-block;
    height: 32px;
    margin: 16px 16px;
    padding: 0 8px;
    line-height: 29px;
    font-family: $font;
    font-size: 1rem;
    font-weight: $fontWeight;
    text-decoration: none;
    // text-transform: uppercase;
    color: $light;
    transition: color $transitionTime $transitionType;
    cursor: pointer;
  }

  a::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    bottom: 100%;
    left: 0;
    background-color: $light;
    opacity: 0;
    transform: translateY(-5px);
    transition: opacity $transitionTime $transitionType, transform $transitionTime $transitionType;
  }

  a:hover::before {
    opacity: 0.42;
    transform: translateY(0px);
  }

  a::after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    top: 100%;
    left: 0;
    background-color: $light;
    opacity: 0;
    transform: translateY(5px);
    transition: opacity $transitionTime $transitionType, transform $transitionTime $transitionType;
  }

  a:hover::after {
    opacity: 0.42;
    transform: translateY(0px);
  }
}

// @media only screen and (min-width: $MOBILE_MAX_WIDTH) {
//   #topbar.transparent {
//     box-shadow: none;
//     border-right-color: transparent;
//     // background-color: transparent;
//   }

//   #topbar-nav.transparent {
//     background-color: transparent;
//     background: none;

//     a { color: $light; }
//     a::before, a::after {  background-color: $light; }
//   }
// }

// mobile
#topbar-toggle {
  position: absolute;
  display: inline-block;
  height: 54px;
  margin: 0;
  padding: 15px;
  top: 5px;
  right: 5px;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
  overflow: visible;
  cursor: pointer;

  .topbar-toggle-box {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;

    .topbar-toggle-inner,
    .topbar-toggle-inner::before,
    .topbar-toggle-inner::after {
      position: absolute;
      display: block;
      width: 40px;
      height: 4px;
      border-radius: 4px;
      background-color: $dark;
    }

    .topbar-toggle-inner::before,
    .topbar-toggle-inner::after {
      content: '';
      display: block;
    }

    .topbar-toggle-inner {
      top: 50%;
      margin-top: -2px;
      transition-duration: 0.22s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

      &::before {
        top: -10px;
        transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
      }

      &::after {
        bottom: -10px;
        transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }
  }

  &.is-active .topbar-toggle-box {
    .topbar-toggle-inner {
      transform: rotate(225deg);
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &::before {
        top: 0;
        opacity: 0;
        transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
      }

      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }
}

@media only screen and (max-width: $MOBILE_MAX_WIDTH) {
  #topbar {
    border-right: none;
    background: none;
    background-color: $light;

    .topbar-logo {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .topbar-nav-backdrop {
    position: fixed;
    top: $TOPBAR_HEIGHT;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $shadow;
    opacity: 0;
    z-index: 999;
  }

  #topbar-nav {
    position: fixed;
    width: 250px;
    height: calc(100% - #{$TOPBAR_HEIGHT});
    // height: 100vh;
    margin: 0;
    padding: 16px;
    top: $TOPBAR_HEIGHT;
    right: -250px;
    bottom: 0;
    background: none;
    background-color: $light;
    box-shadow: 0 10px 10px 0 $shadow;
    transition: right $transitionTime $transitionType;
    z-index: 1000;

    a {
      display: block;
      color: $dark;
    }

    a::before,
    a::after {
      background-color: $dark;
    }
  }

  #topbar-nav.open {
    right: 0;
  }
}
